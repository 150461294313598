import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { formatDistanceToNow, parseISO, isValid } from 'date-fns';
import ButtonGroup from '@mui/material/ButtonGroup';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

const firebaseConfig = {
  apiKey: "AIzaSyC7_5seO3WWVTh7RP0BjGLJ2sanMkJEFoY",
  authDomain: "smart-pir-sensor.firebaseapp.com",
  databaseURL: "https://smart-pir-sensor-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "smart-pir-sensor",
  storageBucket: "smart-pir-sensor.appspot.com",
  messagingSenderId: "599302873758",
  appId: "1:599302873758:web:912ea58cc838eeb058118f",
  measurementId: "G-2RQFKMMT5L"
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database();

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'firstName', label: 'Detected', alignRight: false },
  { id: 'online', label: 'Online Status', alignRight: false },
  { id: 'motion', label: 'Motion status', alignRight: false },
  { id: 'switchStatus', label: 'Switch Status', alignRight: false },
  { id: 'firstName', label: 'Name', alignRight: false },
  { id: 'email', label: 'email', alignRight: false },
  { id: 'alert', label: 'Alert', alignRight: false },
  { id: '' },
];

// Helper function to compare two items based on a specified property
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// Function to return the comparator based on the sort order and property
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Function to apply sorting and filtering to the array of items
export function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el) => el[0]);
}

export function applySortFilterWithType(array, comparator, query, filterType) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  let filteredArray = stabilizedThis.map((el) => el[0]);
  console.log(filteredArray)
  if (query) {
    filteredArray = filter(filteredArray, (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  if (filterType === 'active') {
    filteredArray = filter(filteredArray, (_user) => _user.status.Status !== 'Unknown');
  }
  if (filterType === 'offline') {
    filteredArray = filter(filteredArray, (_user) => _user.name !== 'Unknown');
  }
  if (filterType === 'online') {
    filteredArray = filter(filteredArray, (_user) => _user.email !== 'Unknown');
  }
  if (filterType === 'trigger') {
    filteredArray = filter(filteredArray, (_user) => _user.statusV !== 'Unknown');
  }
  if (filterType === 'all') {
    filteredArray = filter(filteredArray, (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return filteredArray;
}

const isOldHeartbeat = (heartbeat) => {
  const twentyMinutesAgo = new Date(Date.now() - 20 * 60 * 1000); // 20 minutes ago
  return parseISO(heartbeat) < twentyMinutesAgo;
};

export default function User() {
  const [USERLIST, setUSERLIST] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [filterType, setFilterType] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await database.ref().once('value'); // Retrieve the entire database
        const data = snapshot.val();
        if (data) {
          const userList = Object.keys(data).map((key) => ({
            id: key,
            heartbeat: data[key].heartbeat,
            status: data[key].status || 'unknown', // Default value if status is undefined
            statusV: data[key]["Last update"] || 'unknown', // Default value if statusV is undefined
            switch: data[key].switch || 'off', // Default value if switch is undefined
            switchStatus: data[key].switchStatus || 'Off',
            firstName: data[key].firstName || 'Unknown', // Default value if firstName is undefined
            lastName: data[key].lastName || 'Unknown', // Default value if lastName is undefined
            email: data[key].email || 'Unknown' // Default value if email is undefined
          }));
          console.log('User List:', userList);
          setUSERLIST(userList);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.firstName); // Changed from name to firstName
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterType = (type) => {
    setFilterType(type);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const filteredUsersWithType = applySortFilterWithType(USERLIST, getComparator(order, orderBy), filterName, filterType);

  const isUserNotFound = filteredUsers.length === 0;
  const isUserNotFoundWithType = filteredUsersWithType.length === 0;

  const handleToggleSwitchStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 'On' ? 'Off' : 'On';
    try {
      await database.ref(`${id}/switchStatus`).set(newStatus);
      setUSERLIST((prev) =>
        prev.map((user) =>
          user.id === id ? { ...user, switchStatus: newStatus } : user
        )
      );
    } catch (error) {
      console.error('Error updating switch status:', error);
    }
  };



  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
            {console.log(USERLIST)}
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <div style={{ overflowX: 'auto' }}>
              <TableContainer sx={{ minWidth: 1080 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, heartbeat, status, statusV, switchStatus, firstName, lastName, email } = row;
                      const isItemSelected = selected.indexOf(firstName) !== -1;
                      const parsedHeartbeat = parseISO(heartbeat);
                      const heartbeatValid = isValid(parsedHeartbeat);

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, firstName)}
                            />
                          </TableCell>
                          <TableCell align="left">{id}</TableCell>
                          <TableCell align="left">
                            {heartbeatValid ? (
                              <Label variant="ghost" color={isOldHeartbeat(heartbeat) ? 'error' : 'success'}>
                                {formatDistanceToNow(parsedHeartbeat, { addSuffix: true })}
                              </Label>
                            ) : (
                              <Label variant="ghost" color="error">
                                Invalid Date
                              </Label>
                            )}
                          </TableCell>
                          <TableCell align="left">{sentenceCase(status)}</TableCell>
                          <TableCell align="left">{statusV}</TableCell>
                          {/* <TableCell align="left">{switchStatus}</TableCell> */}
                          <TableCell>
                          <Button
                            variant="contained"
                            color={switchStatus === 'On' ? 'primary' : 'secondary'}
                            onClick={() => handleToggleSwitchStatus(id, switchStatus)}
                          >
                            {switchStatus}
                          </Button>
                        </TableCell>
                          <TableCell align="left">{firstName}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">
                            {heartbeatValid && isOldHeartbeat(heartbeat) ? (
                              <Label variant="ghost" color="error">
                                Alert
                              </Label>
                            ) : (
                              <Label variant="ghost" color="success">
                                No Alert
                              </Label>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <UserMoreMenu />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Container style={{background:'#E9FCD4', paddingTop:'20px',paddingBottom:'20px', marginTop:'10px', borderRadius:'15px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
            {console.log(USERLIST)}
          </Typography>
          <ButtonGroup variant="contained" aria-label="Basic button group">
            <Button style={{background:'#229A15'}} onClick={() => handleFilterType('active')}>Active</Button>
            <Button style={{background:'#229A15'}} onClick={() => handleFilterType('offline')}>Offline</Button>
            <Button style={{background:'#229A15'}} onClick={() => handleFilterType('online')}>Online</Button>
            <Button style={{background:'#229A15'}} onClick={() => handleFilterType('trigger')}>Trigger</Button>
            <Button style={{background:'#229A15'}} onClick={() => handleFilterType('all')}>All</Button>
          </ButtonGroup>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <div style={{ overflowX: 'auto' }}>
              <TableContainer sx={{ minWidth: 1080 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsersWithType.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, heartbeat, status, statusV, switchStatus, firstName, lastName, email } = row;
                      const isItemSelected = selected.indexOf(firstName) !== -1;
                      const parsedHeartbeat = parseISO(heartbeat);
                      const heartbeatValid = isValid(parsedHeartbeat);

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, firstName)}
                            />
                          </TableCell>
                          <TableCell align="left">{id}</TableCell>
                          <TableCell align="left">
                            {heartbeatValid ? (
                              <Label variant="ghost" color={isOldHeartbeat(heartbeat) ? 'error' : 'success'}>
                                {formatDistanceToNow(parsedHeartbeat, { addSuffix: true })}
                              </Label>
                            ) : (
                              <Label variant="ghost" color="error">
                                Invalid Date
                              </Label>
                            )}
                          </TableCell>
                          <TableCell align="left">{sentenceCase(status)}</TableCell>
                          <TableCell align="left">{statusV}</TableCell>
                          <TableCell align="left">{switchStatus}</TableCell>
                          <TableCell align="left">{firstName}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">
                            {heartbeatValid && isOldHeartbeat(heartbeat) ? (
                              <Label variant="ghost" color="error">
                                Alert
                              </Label>
                            ) : (
                              <Label variant="ghost" color="success">
                                No Alert
                              </Label>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <UserMoreMenu />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFoundWithType && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
